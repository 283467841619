import { useTranslation } from 'react-i18next'
import useData from '../queries/useData'
import ErrorPage from './Error'
import Page from '../components/Page'
import Organization from '../components/Organization/Organization'
import PayForm from '../components/PayForm/PayForm'

const MainPage = () => {
    const { t } = useTranslation()
    const {
        data,
        isLoading,
        isError,
        error,
    } = useData()

    if (isLoading) {
        return (
            <Page>
                <div className="flex justify-center items-center">
                    {t('loading')}
                </div>
            </Page>
        )
    }

    if (isError || !data) {
        const errorMessage = (error as Error).message
        return <Page>
            <ErrorPage message={errorMessage} />
        </Page>
    }

    return (
        <Page>
            <Organization employee={data.employee} />
            <PayForm organization={data.organization} />
        </Page>
    )
}

export default MainPage
