import React, { useEffect, useRef, useState } from 'react'
import Input from '../../../common/Input'
import { Currency } from '../../../../entities/countries'
interface CurrencyInputProps {
    amount: number
    onChange: (amount: number) => void
    currency: Currency
}

const CurrencyInput = ({ amount, onChange, currency }: CurrencyInputProps) => {
    const inputRef = useRef<HTMLInputElement>(null)
    const [caretPosition, setCaretPosition] = useState({
        index: getNumberLength(amount),
    })
    const amountWithCurrency = `${amount || ''} ${currency}`
    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.setSelectionRange(
                caretPosition.index,
                caretPosition.index,
            )
        }
    }, [caretPosition])

    const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newAmount = getNewAmount(e.target.value)
        handleCaretPosition(newAmount)

        const dontChangeValue = newAmount === amount || isNaN(newAmount)

        if (dontChangeValue) {
            return
        }

        onChange(newAmount)
    }

    const getNewAmount = (inputValue: string) => {
        const amountWithoutCurrency = inputValue.replaceAll(/[\D-]/gi, '') // remove any non-digit symbols
        let newAmount = parseFloat(amountWithoutCurrency || '0')
        if (newAmount < 0) {
            newAmount *= -1
        }
        return newAmount
    }

    const handleCaretPosition = (newAmount: number) => {
        const newAmountLength = getNumberLength(newAmount)

        if (inputRef.current) {
            const caretPosition = inputRef.current.selectionStart || 0
            const caretIsNotOnNumberPart = caretPosition >= newAmountLength // 100 RON| or 100 RO|N or 100 |RON etc.

            if (caretIsNotOnNumberPart) {
                const lastNumberPosition = newAmountLength
                setCaretPosition({
                    index: lastNumberPosition,
                })
                return
            }

            setCaretPosition({
                index: caretPosition,
            })
        }
    }

    return (
        <Input
            ref={inputRef}
            pattern="\d*"
            inputMode="numeric"
            className={`flex-1 px-14 w-full h-full`}
            placeholder={'Enter the amount'}
            value={amountWithCurrency}
            onChange={onChangeInput}
        />
    )
}

const getNumberLength = (num: number) => {
    if (num === 0) {
        return 1
    }

    return Math.ceil(Math.log10(num + 1))
}

export default CurrencyInput
