import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'

import MainPage from './pages/Main'
import ThanksPage from './pages/Thanks'
import NotFound from './pages/NotFound'
import InternalServerError from './pages/InternalServerError'

import './i18n'
import './App.css'

const router = createBrowserRouter(
    [
        {
            index: true,
            element: <MainPage />,
        },
        {
            path: '/thanks',
            element: <ThanksPage />,
        },
        {
            path: '/404',
            element: <NotFound />,
        },
        {
            path: '/500',
            element: <InternalServerError />,
        },
        {
            path: '*',
            element: <Navigate to='/404' />,
        },
    ],
    { basename: '/pay' },
)

const App = () => {
    return <RouterProvider router={router} />
}

export default App
