interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string
}
const Button = ({ className, ...props }: ButtonProps) => {
    const defaultClassName = `p-2 bg-yellow-30 rounded-[37px]
        font-semibold text-xl
        flex justify-center items-center
        min-w-[200px] min-h-[60px]
        disabled:opacity-70
        active:shadow-base active:bg-yellow-40 hover:bg-yellow-40
    `
    const combinedClass = className
        ? `${defaultClassName} ${className}`
        : defaultClassName
    return <button className={combinedClass} {...props} />
}

export default Button
