import { useTranslation } from 'react-i18next'
import bg from '../../assets/background.png'
import LanguageSwitch from './components/LanguageSwitch'

const Page = ({ children }: { children: React.ReactNode }) => {
    const { i18n } = useTranslation()

    const onChangeLanguage = (lang: string) => {
        void i18n.changeLanguage(lang)
    }
    return (
        <div
            style={{
                backgroundImage: `url('${bg}')`,
            }}
            className="w-full min-h-full pt-40 flex overflow-x-hidden"
        >
            <LanguageSwitch
                chosenLanguage={i18n.language}
                onChangeLanguage={onChangeLanguage}
                className="absolute top-5 right-5"
            />
            <div className="pt-20 pb-10 text-base flex-1 rounded-t-[40px] bg-white flex flex-col items-center gap-5 relative max-w-full">
                {children}
            </div>
        </div>
    )
}

export default Page
