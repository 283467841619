import { useTranslation } from 'react-i18next'
import Button from '../common/Button'
import Checkbox from '../common/Checkbox'
import Coins from './components/Coins'
import ReviewStars from './components/ReviewStars'
import CurrencyInput from './components/CurrencyInput'
import useForm from './hooks/useForm'
import { Organization } from '../../entities/organization'

const PayForm = ({ organization }: { organization: Organization }) => {
    const { t } = useTranslation()
    const {
        form: { amount, stars, tos },
        disableSubmit,
        onChangeAmount,
        onChangeStars,
        onChangeTOS,
        onSubmit,
    } = useForm(organization)


    const toslink = `https://nocoins.today/${organization.country}/terms-and-conditions`

    return (
        <>
            <div className="max-w-full flex items-center h-10 mb-2.5">
                <CurrencyInput
                    amount={amount}
                    onChange={onChangeAmount}
                    currency={organization.currency}
                />
            </div>
            <Coins chosenValue={amount} onChoose={onChangeAmount} currency={organization.currency} />
            <div
                className="flex flex-col items-center gap-2.5 mt-2.5 mb-6"
            >
                <div>{t('rate our service')}</div>
                <ReviewStars value={stars} onChange={onChangeStars} />
            </div>
            <div
                className="max-w-full flex justify-center items-center gap-2.5 mb-2.5"
            >
                <Checkbox value={tos} onChange={onChangeTOS} />
                <div>
                    {t('I agree with')}{' '}
                    <a
                        target="_blank"
                        rel='noreferrer'
                        href={toslink}
                        className="text-yellow-30"
                    >
                        {t('tos')}
                    </a>
                </div>
            </div>
            <Button disabled={!!disableSubmit} onClick={onSubmit}>
                {`${t('Pay')} ${amount ? `${amount} ${organization.currency}` : ''}`}
            </Button>
        </>
    )
}

export default PayForm
