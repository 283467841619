import {useTranslation} from 'react-i18next'
import heart from '../assets/heart.svg'

const ThanksPage = () => {
    const {t} = useTranslation()

    return (
        <div className="flex h-screen w-screen flex-col items-center gap-4 bg-yellow-30 pt-24 text-center font-bold text-white">
            <div className="text-[2.5rem]">{t('thanks')}</div>
            <div>
                <img src={heart} alt="thank you" />
            </div>
            <div className="mt-6 text-2xl">
                {t('thanksDescription')}
            </div>
        </div>
    )
}

export default ThanksPage
