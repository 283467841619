import { useTranslation } from 'react-i18next'
import Avatar from './components/Avatar'
import { Employee } from '../../entities/organization'

const Organization = ({ employee }: { employee: Employee }) => {
    const { t } = useTranslation()
    return (
        <>
            <Avatar logo={employee.avatar} className="absolute top-0 -translate-y-1/2" />
            <div className="flex flex-col items-center px-10">

                <div className="font-bold text-xl mb-0.5">
                    {employee.name}
                </div>
                <div className="text-grey-10 mb-2.5">{t(employee.job)}</div>
            </div>
        </>
    )
}

export default Organization
