import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

const resources = {
    en: {
        translation: {
            language: 'EN',
            loading: 'Loading...',
            'default error': 'Something went wrong.',
            currency: 'RON',
            waiter: 'waiter',
            'rate our service': 'Please rate our service',
            'I agree with': 'I agree with',
            tos: 'TERMS&CONDITIONS',
            Pay: 'Pay',
            thanks: 'Thanks!',
            thanksDescription: 'We are waiting for you again!',
            organization: 'organization',
            errorNotFound: 'Not Found',
            errorNotFoundDescription: 'Looks like the page you requested\ncould not be found',
            errorInternalServer: 'Internal Server Error',
            errorInternalServerDescription: "We're working towards craeting something better\nWe won't be long",
            goBackButton: "GO BACK",
        },
    },
    ro: {
        translation: {
            language: 'RO',
            loading: 'Se încarcă...',
            'default error': 'Ceva n-a mers bine.',
            currency: 'RON',
            waiter: 'ospătar',
            'rate our service': 'Va rugam sa evaluați serviciile noastre',
            'I agree with': 'Sunt de acord cu',
            tos: 'termenii și condițiile',
            Pay: 'Plateste',
            thanks: 'Mulțumim!',
            thanksDescription: 'Vă mai așteptăm!',
            organization: 'organizare',
            errorNotFound: 'Nu a fost găsit',
            errorNotFoundDescription: 'Se pare că pagina pe care ați solicitat-o\nnu a putut fi găsită',
            errorInternalServer: 'Internal Server Error',
            errorInternalServerDescription: "Lucrăm pentru a îmbunătăți serviciile oferite.\nNe întoarcem repede.",
            goBackButton: "ÎNAPOI",
        },
    },
    bg: {
        translation: {
            language: 'BG',
            loading: 'Зареждане...',
            'default error': 'Нещо се обърка.',
            currency: 'BGN',
            waiter: 'сервитьор',
            'rate our service': 'Моля, оценете обслужването ни',
            'I agree with': 'Съгласен съм с',
            tos: 'ОБЩИТЕ УСЛОВИЯ',
            Pay: 'Заплатете',
            thanks: 'Много благодаря!',
            thanksDescription: 'Очакваме Ви отново!',
            organization: 'организация',
            errorNotFound: 'Не е намерено',
            errorNotFoundDescription: 'Изглежда, че поисканата от вас страница\nне може да бъде намерена',
            errorInternalServer: 'Вътрешна грешка на сървъра',
            errorInternalServerDescription: "Работим за създаването на нещо по-добро.\nНяма да се забавим дълго",
            goBackButton: "ВЪРНИ СЕ",
        },
    },
}

const initialLanguageParamKey = 'lang'

const getInitialLanguage = () => {
    let initialLangauge = 'en'

    const browserLanguage = navigator.language
    if (browserLanguage in resources) {
        initialLangauge = browserLanguage
    }

    const params = new URLSearchParams(window.location.search)
    const urlLanguage = params.get(initialLanguageParamKey)
    if (urlLanguage && urlLanguage in resources) {
        initialLangauge = urlLanguage
    }

    return initialLangauge
}

void i18n.use(initReactI18next).init({
    resources,
    lng: getInitialLanguage(),
    interpolation: {
        escapeValue: false,
    },
})

export default i18n
