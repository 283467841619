const ErrorPageHeader = ({ status, description }: { status: number, description: string }) => {
    return (
        <div className='bg-yellow-40 text-white font-bold rounded-b-full flex flex-col items-center pt-5 lg:pt-20 h-44 w-80 lg:h-[32rem] lg:w-[57.5rem]'>
            <div className="text-5xl lg:text-9xl">{status}</div>
            <div className="text-xl lg:text-5xl">{description}</div>
        </div>
    )
}

export default ErrorPageHeader
