import { Currency } from '../../../../entities/countries'

interface CoinButtonProps {
    amount: number
    onClick: (amount: number) => void
    isChosen: boolean
    currency: Currency
}

const CoinButton = ({ amount, onClick, isChosen, currency }: CoinButtonProps) => {
    return (
        <button
            className={`w-[60px] h-[60px]
                rounded-full font-bold text-white
                flex flex-col justify-center items-center
                active:shadow-base active:bg-yellow-40 hover:bg-yellow-40
                ${isChosen ? 'bg-yellow-40 shadow-base' : 'bg-yellow-30'}
            `}
            onClick={() => onClick(amount)}
        >
            <div className="text-xl leading-6">{amount}</div>
            <div className="leading-5">{currency}</div>
        </button>
    )
}

export default CoinButton
