import React from 'react'
import defaultLogo from '../../../../assets/default-place.png'
interface AvatarProps {
    logo: string
    className?: string
    [x: string]: string | number | undefined
}
const Avatar = ({ logo, className, ...props }: AvatarProps) => {
    const defaultClassName = `w-[140px] h-[140px] border-[10px] rounded-full border-white flex justify-center items-center overflow-hidden  `
    const combinedClass = className
        ? `${defaultClassName} ${className}`
        : defaultClassName

    const logoToRender = logo || defaultLogo
    return (
        <div className={combinedClass} {...props}>
            <img src={logoToRender} alt={'avatar'} />
        </div>
    )
}

export default Avatar
