import React from 'react'
import checkmark from '../../../assets/checkmark.svg'

interface CheckboxProps {
    value: boolean
    onChange: () => void
}

const Checkbox = ({ value, onChange }: CheckboxProps) => {
    return (
        <div
            onClick={onChange}
            className={`w-6 h-6 rounded-full border-2 border-yellow-30 
                flex justify-center items-center
                ${value ? 'bg-yellow-30' : ''}
                cursor-pointer
            `}
        >
            {value && <img src={checkmark} alt={'checkmark'} />}
        </div>
    )
}

export default Checkbox
