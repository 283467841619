import { useTranslation } from 'react-i18next'
import ErrorPage from '../components/ErrorPage'

const NotFound = () => {
    const { t } = useTranslation()
    return (
        <ErrorPage>
            <ErrorPage.Header status={404} description={t('errorNotFound')} />
            <ErrorPage.Description>{t('errorNotFoundDescription')}</ErrorPage.Description>
            <ErrorPage.BackButton />
        </ErrorPage>
    )
}

export default NotFound
