import { useQuery } from '@tanstack/react-query'
import {
    Country,
    currencyByCountry,
    tipLimitByCurrency,
} from '../entities/countries'
import { Employee, Organization } from '../entities/organization'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

export interface Data {
    id: number
    organization: Organization
    employee: Employee
}
const ERROR_NOT_FOUND = 404
const ERROR_INTERNAL_SERVER_ERROR = 500

const useData = () => {
    const navigate = useNavigate()
    const params = new URLSearchParams(window.location.search)
    const qId = params.get('q')
    const data = useQuery<Data>(['config'], query(qId), {
        staleTime: Infinity,
        refetchOnWindowFocus: false,
    })
    useEffect(() => {
        const error = data.error as Error | null
        if (!error) {
            return
        }
        if (error.message === ERROR_NOT_FOUND.toString()) {
            navigate('/404', { replace: true })
        }
        if (error.message === ERROR_INTERNAL_SERVER_ERROR.toString()) {
            navigate('/500', { replace: true })
        }

    }, [data.error, navigate])

    return {
        ...data,
        data: data.data,
    }
}

interface ResponseData {
    id: number
    organization: {
        id: number
        name: string
        country: Country
        logo: string
    }
    team_member: {
        avatar: string
        id: number
        name: string
        role: string
    }
}


const query = (qId: string | null) => async () => {
    if (!qId) {
        throw new Error(ERROR_NOT_FOUND.toString())
    }

    const url = `${window.location.origin}/api/v1/qrs/${qId}`

    const response = await fetch(url)

    if (!response.ok) {
        if (response.status === ERROR_NOT_FOUND) {
            throw new Error(ERROR_NOT_FOUND.toString())
        }
        if (response.status === ERROR_INTERNAL_SERVER_ERROR) {
            throw new Error(ERROR_INTERNAL_SERVER_ERROR.toString())
        }
        throw new Error('Network response was not ok')
    }

    return transformResponse((await response.json()) as ResponseData)
}

const transformResponse = (rd: ResponseData): Data => {
    const currency = currencyByCountry[rd.organization.country]
    const limit = tipLimitByCurrency[currency]
    return {
        id: rd.id,
        organization: {
            id: rd.organization.id,
            name: rd.organization.name,
            country: rd.organization.country,
            currency: currency,
            limit: limit,
            logo: rd.organization.logo,
        },
        employee: {
            id: rd.team_member.id,
            avatar: rd.team_member.avatar || rd.organization.logo,
            name: rd.team_member.name || rd.organization.name,
            job: rd.team_member.role,
        },
    }
}

export default useData
