import { useState } from 'react'
import { useMutation } from '@tanstack/react-query'
import { Currency } from '../../../entities/countries'
import { Organization } from '../../../entities/organization'

interface FormData {
    amount: number
    stars: number
    tos: boolean
}

const defaultAmountByCurrency: Record<Currency, number> = {
    'BGN': 2,
    'RON': 10
}
const expensiveRestrauntId = "12dcbc47-e92c-445f-b2bb-02d609e5b2f7"

const getDefaultAmountByCurrency = (id: string | null) => {
    if (id === expensiveRestrauntId) {
        return {
            'BGN': 10,
            'RON': 10,
        }
    }
    return {
        'BGN': 2,
        'RON': 10
    }
}
const minimumAmountByCurrency: Record<Currency, number> = {
    'RON': 2,
    'BGN': 1,
}
const getEmptyForm = (currency: Currency, id: string | null) => {
    const defaultAmountByCurrency = getDefaultAmountByCurrency(id)
    return {
        amount: defaultAmountByCurrency[currency] || 10,
        stars: 0,
        tos: true,
    }
}

const useForm = (organization: Organization) => {
    const params = new URLSearchParams(window.location.search)
    const qrId = params.get('q')
    const [form, setForm] = useState(getEmptyForm(organization.currency, qrId))
    const submitMutation = useMutation(mutationFn, {
        onSuccess: (data) => {
            window.location.replace(data.url)
        },
    })
    const onChange = (payload: { prop: keyof FormData, value: FormData[keyof FormData] }) => {
        setForm((f) => ({ ...f, [payload.prop]: payload.value }))
    }

    const onChangeAmount = (amount: number) => {
        const maxlimit = organization.limit
        if (amount > maxlimit) {
            onChange({ prop: 'amount', value: maxlimit })
            return
        }
        onChange({ prop: 'amount', value: amount })
    }
    const onChangeStars = (value: number) =>
        onChange({ prop: 'stars', value: value })
    const onChangeTOS = () => onChange({ prop: 'tos', value: !form.tos })

    const onSubmit = () => {
        if (!qrId) {
            return
        }
        submitMutation.mutate({ qrId, data: form })
    }

    const disableSubmit = !form.amount || !form.tos || form.amount > organization.limit || form.amount < minimumAmountByCurrency[organization.currency]

    return {
        form,
        disableSubmit,
        onChangeAmount,
        onChangeStars,
        onChangeTOS,
        onSubmit,
    }
}

interface ResponseData {
    url: string
}

const mutationFn = async ({ qrId, data }: { qrId: string; data: FormData }) => {
    const url = `${window.location.origin}/api/v1/tips/`
    const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({
            qr_id: qrId,
            amount: data.amount,
            star_rating: data.stars,
        }),
    })

    if (!response.ok) {
        throw new Error('Network response was not ok')
    }

    return (await response.json()) as ResponseData
}
export default useForm
