import { useTranslation } from 'react-i18next'
import ErrorPage from '../components/ErrorPage'

const InternalServerError = () => {
    const { t } = useTranslation()
    return (
        <ErrorPage>
            <ErrorPage.Header status={500} description={t('errorInternalServer')} />
            <ErrorPage.Description>{t('errorInternalServerDescription')}</ErrorPage.Description>
            <ErrorPage.BackButton />
        </ErrorPage>
    )
}

export default InternalServerError
