import React from 'react'
interface InputProps extends React.HTMLProps<HTMLInputElement> {
    className?: string
}
const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({ className, ...props }, ref) => {
        return (
            <input
                ref={ref}
                className={`
                rounded-full border border-black
                text-center text-2xl text-black placeholder:text-grey-20
                ${className || ''}`}
                {...props}
            />
        )
    },
)

export default Input
