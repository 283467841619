import {useTranslation} from 'react-i18next'

const ErrorPage = ({message}: {message?: string}) => {
    const {t} = useTranslation()
    return (
        <div className="flex flex-col justify-center items-center">
            <div>{t('default error')}</div>
            <div>{message}</div>
        </div>
    )
}

export default ErrorPage
