import ErrorPageBackButton from "./components/ErrorPageBackButton"
import ErrorPageDescription from "./components/ErrorPageDescription"
import ErrorPageHeader from "./components/ErrorPageHeader"

const ErrorPage = ({ children }: { children: React.ReactNode }) => {
    return (
        <div className="w-full h-full flex flex-col items-center gap-12 lg:gap-10">
            {children}
        </div>
    )
}

ErrorPage.Header = ErrorPageHeader
ErrorPage.Description = ErrorPageDescription
ErrorPage.BackButton = ErrorPageBackButton

export default ErrorPage
