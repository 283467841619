import React, { ReactElement } from 'react'
import activeStar from '../../../../assets/star_active.svg'
import inactiveStar from '../../../../assets/star_inactive.svg'

interface ReviewStarsProps {
    value: number
    onChange: (value: number) => void
}
const ReviewStars = ({ value, onChange }: ReviewStarsProps) => {
    const stars: ReactElement[] = []

    const onReview = (r: number) => () => {
        onChange(r)
    }

    for (let i = 1; i <= 5; i++) {
        const icon = i <= value ? activeStar : inactiveStar
        const star = (
            <img
                className="cursor-pointer"
                src={icon}
                alt={`${i} stars`}
                key={i}
                onClick={onReview(i)}
            />
        )
        stars.push(star)
    }
    return <div className="flex flex-between gap-2.5">{stars}</div>
}

export default ReviewStars
