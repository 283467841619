import React, { useEffect, useRef, useState } from 'react'
import enIcon from '../../../../assets/uk.svg'
import roIcon from '../../../../assets/romania.svg'
import bgIcon from '../../../../assets/bulgaria.svg'
import arrowBottom from '../../../../assets/arrow-bottom.svg'
import arrowTop from '../../../../assets/arrow-top.svg'

interface LanguageSwitchrops {
    chosenLanguage: string
    onChangeLanguage: (lang: string) => void
    className?: string
}

const languages = [
    {
        value: 'en',
        label: 'EN',
        icon: enIcon,
    },
    {
        value: 'ro',
        label: 'RO',
        icon: roIcon,
    },
    {
        value: 'bg',
        label: 'BG',
        icon: bgIcon,
    },
]

const LanguageSwitch = ({
    chosenLanguage,
    onChangeLanguage,
    className,
}: LanguageSwitchrops) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const optionsElement = useRef<HTMLDivElement>(null)
    const chosenLanguageData =
        languages.find((l) => l.value === chosenLanguage) || languages[0]

    const onClickChosenOption = () => {
        setIsDropdownOpen((open) => !open)
    }

    const onClickDropdownOption = (lang: string) => () => {
        onChangeLanguage(lang)
        setIsDropdownOpen(false)
    }

    useEffect(() => {
        // autofocus
        if (isDropdownOpen) {
            optionsElement.current?.focus()
        }
    }, [isDropdownOpen])

    const onBlurOptions = () => {
        setIsDropdownOpen(false)
    }
    const defaultClassName = `flex flex-col
        bg-white rounded-[1.125rem]
        cursor-pointer text-sm
        z-10
    `
    const combinedClass = className
        ? `${defaultClassName} ${className}`
        : defaultClassName

    const langOptionClass = 'px-2.5 py-1.5 flex gap-px'
    const langLabelClass = 'ml-[0.4375rem] mr-[0.5625rem]'
    return (
        <div
            className={combinedClass}
            tabIndex={0}
            onBlur={onBlurOptions}
            ref={optionsElement}
        >
            <div className={langOptionClass} onClick={onClickChosenOption}>
                <img
                    src={chosenLanguageData.icon}
                    alt={chosenLanguageData.label}
                />
                <span className={langLabelClass}>
                    {chosenLanguageData.label}
                </span>
                <img
                    src={isDropdownOpen ? arrowTop : arrowBottom}
                    alt={'open'}
                />
            </div>

            {isDropdownOpen &&
                languages.map((l) => {
                    if (l.value === chosenLanguage) {
                        return null
                    }

                    const langClass = `${langOptionClass}
                        ${l.value === chosenLanguage ? 'bg-yellow-10' : ''}
                    `

                    return (
                        <div
                            key={l.value}
                            className={langClass}
                            onClick={onClickDropdownOption(l.value)}
                        >
                            <img src={l.icon} alt={l.label} />
                            <span className={langLabelClass}>{l.label}</span>
                        </div>
                    )
                })}
        </div>
    )
}

export default LanguageSwitch
