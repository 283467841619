export type Country = 'ro' | 'bg'

export type Currency = 'RON' | 'BGN'

export const currencyByCountry: Record<Country, Currency> = {
    ro: 'RON',
    bg: 'BGN',
}

export const tipLimitByCurrency: Record<Currency, number> = {
    RON: 1000,
    BGN: 500,
}
