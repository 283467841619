import React from 'react'
import CoinButton from './CoinButtons'
import { Currency } from '../../../../entities/countries'

interface CoinsProps {
    chosenValue: number
    onChoose: (value: number) => void
    currency: Currency
}

const defaultBGNCoinAmounts = [2, 5, 10, 20]
const expensiveBGNCoinAmounts = [5, 10, 30, 50]
const defaultRONCoinAmounts = [2, 5, 10, 20]

const expensiveRestrauntId = "12dcbc47-e92c-445f-b2bb-02d609e5b2f7"

const getCoinAmountsByCurrency = (id: string | null) => {
    if (id === expensiveRestrauntId) {
        return {
            'BGN': expensiveBGNCoinAmounts,
            'RON': defaultRONCoinAmounts
        }
    }
    return {
        'BGN': defaultBGNCoinAmounts,
        'RON': defaultRONCoinAmounts
    }
}

const Coins = ({ chosenValue, onChoose, currency }: CoinsProps) => {
    const params = new URLSearchParams(window.location.search)
    const qId = params.get('q')
    const coinAmountsByCurrency = getCoinAmountsByCurrency(qId)
    const coinAmounts = coinAmountsByCurrency[currency]
    return (
        <div className="flex justify-between gap-6">
            {coinAmounts.map((ca) => (
                <CoinButton
                    currency={currency}
                    isChosen={ca === chosenValue}
                    key={ca}
                    amount={ca}
                    onClick={(am) => {
                        onChoose(am)
                    }}
                />
            ))}
        </div>
    )
}

export default Coins
