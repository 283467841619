import { useTranslation } from "react-i18next"
import Button from "../../common/Button"
import { useNavigate } from "react-router-dom"

const ErrorPageBackButton = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const onClickBackButton = () => {
        navigate(-1)
    }

    return (
        <Button onClick={onClickBackButton} className="text-white font-bold">
            {t('goBackButton')}
        </Button>
    )
}

export default ErrorPageBackButton
